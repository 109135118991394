import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  CheckboxGroup,
  Checkbox,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { assignClient } from '../../../../redux/actions/adminAction';
import { FaUserEdit } from 'react-icons/fa';

const AssignUser = ({ clients, traders, fetchAllAdmin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  const handleAssign = async () => {
    if (selectedClients.length === 0) {
      toast({
        title: 'Validation Error',
        description: 'Please select a trader and at least one client.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const assignData = {
      client_id: selectedClients,
      trader_id: parseInt(traders?.user_id, 10),
    };

    setLoading(true);
    try {
      await dispatch(assignClient(assignData)).then((response) => {
        if (response) {
          toast({
            title: 'Assignment Successful',
            description:
              'Clients have been assigned to the trader successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          onClose();
          fetchAllAdmin();
          setSelectedClients([]);
          setSelectedTrader('');
        }
      });
    } catch (error) {
      toast({
        title: 'Error Assigning Clients',
        description: error.message || 'Something went wrong.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FaUserEdit fontSize={'24px'} color="blue" onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Clients to {traders.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <FormControl mb={4}>
                            <FormLabel>Select Trader</FormLabel>
                            <ChakraSelect
                                placeholder="Select Trader"
                                value={selectedTrader}
                                onChange={(e) => setSelectedTrader(e.target.value)}
                            >
                                {traders.map((trader) => (
                                    <option key={trader.user_id} value={trader.user_id}>
                                        {trader.name}
                                    </option>
                                ))}
                            </ChakraSelect>
                        </FormControl> */}
            <FormControl>
              <FormLabel>Select Clients</FormLabel>
              <Select
                options={clients?.map((client) => ({
                  value: client.id,
                  label: client.name,
                }))}
                value={selectedClients.map((id) => ({
                  value: id,
                  label: clients.find((client) => client.id === id)?.name,
                }))}
                onChange={(selectedOptions) =>
                  setSelectedClients(
                    selectedOptions.map((option) => option.value),
                  )
                }
                isMulti
                placeholder="Select clients"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} isDisabled={loading}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleAssign}
              isLoading={loading}
              ml={3}
            >
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignUser;
