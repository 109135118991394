import { apiurl } from 'utils/config';
import {
  EXIT_HOLDING_FAILURE,
  EXIT_HOLDING_REQUEST,
  EXIT_HOLDING_SUCCESS,
} from '../constants/index';
import api from '../axiosConfig';

export const exitHolding = (exitData) => async (dispatch) => {
  dispatch({ type: EXIT_HOLDING_REQUEST });

  try {
    const response = await api.post(
      `${apiurl}api/trading/exit/position/`,
      exitData,
    );

    dispatch({ type: EXIT_HOLDING_SUCCESS, payload: response.data });

    return { success: true, data: response.data }; // Return success response
  } catch (error) {
    const errorMessage = error.response ? error.response.data : 'Network error';

    dispatch({ type: EXIT_HOLDING_FAILURE, payload: errorMessage });

    return { success: false, error: errorMessage }; // Return error response
  }
};
