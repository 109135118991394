import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    Select,
    Card,
    useColorModeValue,
    Tooltip,
} from '@chakra-ui/react';
import { MdAdd, MdArrowUpward, MdArrowDownward, MdDeleteOutline, } from 'react-icons/md';
import { FaEdit, FaUserEdit } from 'react-icons/fa';
import React, { useCallback, useState, useMemo } from 'react';
import AddAdminForm from './AddAdminForm';
import { downloadCSV } from 'utils/downloadCSV';
import DeleteUser from './DeleteUser';
import AssignUser from './AssignUser';
import ViewAssignUser from './ViewAssignedUser';
import UpdateUser from './UpdateUser';
import { formatDate } from 'utils/formatDate';

export default function AdminTable({
    data,
    fetchAllAdmin,
    addAdmin,
    user
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    let secondaryText = useColorModeValue('gray.700', 'white');


    const handleSort = useCallback((field) => {
        setSortField(field);
        setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc');
    }, []);

    const handlePagination = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const handleItemsPerPageChange = useCallback((e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset page to 1 when rows per page changes
    }, []);

    const sortedData = useMemo(() => {
        if (!sortField) return data;
        return [...data].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortField] > b[sortField] ? 1 : -1;
            } else {
                return a[sortField] < b[sortField] ? 1 : -1;
            }
        });
    }, [data, sortField, sortOrder]);

    const totalPages = Math.ceil(sortedData?.length / itemsPerPage);
    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return sortedData?.slice(startIndex, endIndex);
    }, [sortedData, currentPage, itemsPerPage]);

    const handleDownload = () => {
        const csvData = paginatedData.map(item => ({
            "User ID": item.user_id,
            Name: item.name,
            Email: item.email,
            Role: item.role
        }));
        downloadCSV(csvData, 'admin_data.csv');
    };

    return (
        <Card
            flexDirection="column"
            w="100%"
            borderRadius={"12px"}
            padding={"12px"}
            // px="12px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
        >
            <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
                <Text color={secondaryText} fontSize="22px" fontWeight="700" lineHeight="100%">
                    User Management
                </Text>
                <Flex gap="10px">
                    <Button variant="solid" colorScheme="blue" onClick={onOpen}>
                        Add User
                        <MdAdd />
                    </Button>
                    <Button variant="solid" colorScheme="green" onClick={handleDownload}>
                        Download
                    </Button>
                </Flex>
            </Flex>

            <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AddAdminForm
                            addAdmin={addAdmin}
                            fetchAllAdmin={fetchAllAdmin}
                            onClose={onClose}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Box overflowX="auto">
                <Table variant="simple" color={secondaryText} mb="24px" mt="24px">
                    <Thead>
                        <Tr>
                            {[
                                { label: "#", key: "#" },
                                { label: "User Id", key: "user_id" },
                                { label: "Name", key: "name" },
                                { label: "Email", key: "email" },
                                { label: "Role", key: "role" },
                                { label: "Updated At", key: "updated_at" },
                                { label: "Created At", key: "created_at" },
                                { label: "Action", key: "action" },
                            ].map(({ label, key }) => (
                                <Th
                                    key={key}
                                    fontSize="sm"
                                    color={secondaryText}
                                    fontWeight="600"
                                    whiteSpace="nowrap"
                                    padding={"10px"}
                                    cursor="pointer"
                                    onClick={() => handleSort(key)}
                                >
                                    {label}
                                    {sortField === key && (sortOrder === 'asc' ? <MdArrowUpward /> : <MdArrowDownward />)}
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {paginatedData?.map((item, index) => (
                            <Tr fontSize={"sm"} key={index}>
                                <Td whiteSpace="nowrap" px="10px">
                                    {index + 1 + (currentPage - 1) * itemsPerPage}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    {item?.user_id}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    {item?.name}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    {item?.email}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    {item?.role}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    {formatDate(item?.updated_at)}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    {formatDate(item?.created_at)}
                                </Td>
                                <Td whiteSpace="nowrap" px="10px">
                                    <Flex gap="8px" align="center">
                                        <Tooltip label="Delete this user" fontSize="sm">
                                            <span>
                                                {/* <MdDeleteOutline fontSize="26px" /> */}
                                                <DeleteUser userId={item.user_id} userName={item.name} fetchAllAdmin={fetchAllAdmin} />
                                            </span>
                                        </Tooltip>
                                        <Tooltip label="Edit this user" fontSize="sm">
                                            <span>
                                                <UpdateUser user={item} fetchAllAdmin={fetchAllAdmin} />
                                            </span>
                                        </Tooltip>
                                        {item?.role === 'Trader' && (
                                            <Tooltip label="Assign user" fontSize="sm">
                                                <span>
                                                    {/* <FaUserEdit fontSize="24px" /> */}
                                                    <AssignUser
                                                        clients={user}
                                                        // clients={[{ id: 1, name: "Client A" }, { id: 2, name: "Client B" }]}
                                                        traders={item}
                                                        fetchAllAdmin={fetchAllAdmin}
                                                    />
                                                </span>
                                            </Tooltip>
                                        )}
                                        {item?.role === 'Trader' && (
                                            <Tooltip label="View Assigned user" fontSize="sm">
                                                <span>
                                                    {/* <FaUserEdit fontSize="24px" /> */}
                                                    <ViewAssignUser
                                                        assignedClient={item?.client_id}
                                                        // clients={[{ id: 1, name: "Client A" }, { id: 2, name: "Client B" }]}
                                                        traders={item}
                                                    />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <Flex justify="flex-end" align="center" mt="20px">
                    <Flex gap="10px" align="center">
                        <Select
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            w="100px"
                        >
                            {[10, 20, 30, 50].map(count => (
                                <option key={count} value={count}>
                                    {count}
                                </option>
                            ))}
                        </Select>
                        <Text>
                            Page {currentPage} of {totalPages}
                        </Text>
                    </Flex>
                    <Flex gap="10px" ml="20px" alignItems="center">
                        {/* Previous Button */}
                        <Button
                            onClick={() => handlePagination(currentPage - 1)}
                            variant="outline"
                            colorScheme="blue"
                            isDisabled={currentPage === 1}
                        >
                            &lt;
                        </Button>

                        {/* Current Page Number */}
                        <Text fontWeight="bold" fontSize="md">
                            {currentPage}
                        </Text>

                        {/* Next Button */}
                        <Button
                            onClick={() => handlePagination(currentPage + 1)}
                            variant="outline"
                            colorScheme="blue"
                            isDisabled={currentPage === totalPages}
                        >
                            &gt;
                        </Button>
                    </Flex>

                </Flex>
            </Box>
        </Card>
    );
}
