// actions/brokerActions.js
import api from '../axiosConfig';
import { ADD_ADMIN_SUCCESS, ADD_ADMIN_REQUEST, ADD_ADMIN_FAILURE, GET_ADMIN_REQUEST, GET_ADMIN_SUCCESS, GET_ADMIN_FAILURE, DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE, ADD_ASSIGN_REQUEST, ADD_ASSIGN_SUCCESS, ADD_ASSIGN_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } from '../../redux/constants';
import { apiurl } from 'utils/config';

// Action to add a broker
export const addAdmin = (adminData) => async (dispatch) => {
  dispatch({ type: ADD_ADMIN_REQUEST });
  try {
    const response = await api.post(`${apiurl}api/user/register/`, adminData);
    dispatch({ type: ADD_ADMIN_SUCCESS, payload: response.data });
    return response.data; // Return the response data
  } catch (error) {
    const errorMessage = error.response ? error.response.data : "Network error";
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errorMessage,
    });
    throw error; // Throw the error so it can be caught by the caller
  }
};


// Action to fetch all brokers
export const getAdmin = () => async (dispatch) => {
  dispatch({ type: GET_ADMIN_REQUEST });
  try {
    const response = await api.get(`${apiurl}api/user/register/`);
    dispatch({ type: GET_ADMIN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: GET_ADMIN_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};

export const assignClient = (assignData) => async (dispatch) => {
  dispatch({ type: ADD_ASSIGN_REQUEST });
  try {
    const response = await api.post(`${apiurl}api/user/trader/`, assignData);
    dispatch({ type: ADD_ASSIGN_SUCCESS, payload: response.data });
    return response.data; // Return the response data
  } catch (error) {
    const errorMessage = error.response ? error.response.data : "Network error";
    dispatch({
      type: ADD_ASSIGN_FAILURE,
      payload: errorMessage,
    });
    throw error; // Throw the error so it can be caught by the caller
  }
};

// Action to delete broker
export const deleteUser = (userId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });
  try {
    const response = await api.delete(`${apiurl}api/user/register/${userId}`);
    dispatch({ type: DELETE_USER_SUCCESS });
    return response;
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};

//Update Broker
export const updateUser = (userId, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_REQUEST });
  try {
    const response = await api.put(`${apiurl}api/user/register/${userId}`, updatedData);
    dispatch({ type: UPDATE_USER_SUCCESS });
    return response
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAILURE, payload: error.message });
  }
};
