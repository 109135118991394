import React, { useCallback, useEffect } from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderBook } from '../../../redux/actions/orderBookAction';
import OrderBookTable from './components/OrderBookTable';
import ExecutedOrderBook from './components/ExecutedOrderBook';
import { getAccount } from '../../../redux/actions/accountAction';

export default function OrderBook() {
  const dispatch = useDispatch();
  const { orderBook } = useSelector((state) => state.orderBook);
  const { account } = useSelector((state) => state.account);
  // const { userDetails } = useSelector((state) => state.auth);

  const fetchAllOrder = useCallback(() => {
    dispatch(getOrderBook());
    if (!account?.client_list) {
      dispatch(getAccount());
    }
  }, [dispatch, account?.client_list]);

  useEffect(() => {
    fetchAllOrder();
  }, [fetchAllOrder]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Tabs variant="soft-rounded" colorScheme="blue">
        <TabList>
          <Tab>Open Orders</Tab>
          <Tab>Executed Orders</Tab>
        </TabList>

        <TabPanels>
          {/* Open Orders Tab */}
          <TabPanel>
            <OrderBookTable
              fetchAllOrder={fetchAllOrder}
              client_list={account?.client_list}
              data={orderBook?.open_order}
            />
          </TabPanel>

          {/* Executed Orders Tab */}
          <TabPanel>
            <ExecutedOrderBook
              fetchAllOrder={fetchAllOrder}
              data={orderBook?.excute_order}
              client_list={account?.client_list}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
