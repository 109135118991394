import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
} from '@chakra-ui/react';
import { FaEye } from 'react-icons/fa';
import { formatDate } from '../../../../utils/formatDate'; // Adjust the path for your utility function

const ViewUser = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Function to format array values
  const renderValue = (value) => {
    if (Array.isArray(value)) {
      return (
        <Table size="sm" variant="unstyled">
          <Tbody>
            {value.map((item, index) => (
              <Tr key={index}>
                <Td>
                  {typeof item === 'object' ? (
                    <Table size="xs" variant="unstyled">
                      <Tbody>
                        {Object.entries(item).map(([key, val]) => (
                          <Tr key={key}>
                            <Th textTransform="capitalize">
                              {key.replace(/_/g, ' ')}
                            </Th>
                            <Td>{val?.toString() || 'N/A'}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    item.toString()
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      );
    }
    return typeof value === 'object'
      ? JSON.stringify(value) // Fallback for unexpected objects
      : value?.toString() || 'N/A';
  };

  return (
    <>
      <FaEye
        fontSize="24px"
        color="blue"
        onClick={onOpen}
        style={{ cursor: 'pointer' }}
      />

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Account Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data ? (
              <Table variant="simple" size="sm">
                <Tbody>
                  {Object.entries(data).map(([key, value]) => (
                    <Tr key={key}>
                      <Th textTransform="capitalize">
                        {key === 'client_details'
                          ? 'Client Detail'
                          : key.replace(/_/g, ' ')}
                      </Th>
                      <Td>
                        {key === 'client_details' &&
                        typeof value === 'object' &&
                        value !== null
                          ? `ID: ${value.client_id || 'N/A'} , Name: ${
                              value.client_name || 'N/A'
                            }`
                          : key.includes('created_at') ||
                            key.includes('updated_at')
                          ? formatDate(value) // Format dates
                          : value?.toString() || 'N/A'}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <p>No data available.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={onClose} ml={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewUser;
