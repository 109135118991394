import React, { useCallback, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../../redux/actions/accountAction';
import { getOrderPosition } from '../../../redux/actions/positionAction';
import PositionOrderBook from './components/PositionOrderBook';

export default function OrderPosition() {
  const dispatch = useDispatch();
  const { orderPosition } = useSelector((state) => state.orderPosition);
  const { account } = useSelector((state) => state.account);
  const fetchAllPosition = useCallback(() => {
    dispatch(getOrderPosition());
    if (!account?.client_list) {
      dispatch(getAccount());
    }
  }, [dispatch, account?.client_list]);

  useEffect(() => {
    fetchAllPosition();
  }, [fetchAllPosition]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <PositionOrderBook
        fetchAllPosition={fetchAllPosition}
        data={orderPosition?.clients_positions}
        client_list={account?.client_list}
      />
    </Box>
  );
}
