import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Select as ChakraSelect,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { FaSignOutAlt } from 'react-icons/fa';
import { exitPosition } from '../../../../redux/actions/exitPositionAction';

const ExitPositionModal = ({ positionData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderType, setOrderType] = useState('MARKET');
  const [quantity, setQuantity] = useState('1');
  const [price, setPrice] = useState('0');
  const dispatch = useDispatch();
  const toast = useToast();

  const transactionType = positionData?.netqty > 0 ? 'SELL' : 'BUY';

  const handleExitPosition = async () => {
    const exitData = {
      client_id: `${positionData?.client_details?.id}` ?? '',
      variety: 'NORMAL',
      tradingsymbol: positionData?.tradingsymbol ?? '',
      symboltoken: positionData?.symboltoken ?? '',
      transactiontype: transactionType,
      exchange: positionData?.exchange ?? '',
      ordertype: orderType ?? '',
      producttype: positionData?.producttype ?? '',
      duration: 'DAY',
      price: price ?? '0',
      squareoff: '0',
      stoploss: '0',
      quantity: quantity ?? '1',
    };

    try {
      console.log('exit data payload', exitData);

      const response = await dispatch(exitPosition(exitData));
      console.log('Exit position response:', response);

      if (!response.success) {
        throw new Error(response.error || 'Failed to exit position');
      }

      toast({
        title: 'Position exited successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Exit position error:', error);

      toast({
        title: 'Error exiting position',
        description: error.message || 'Network error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <FaSignOutAlt
        fontSize={23}
        color="red"
        cursor={'pointer'}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="7xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Exit Position</ModalHeader>
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th p="2">Client ID</Th>
                  <Th p="2">Symbol Token</Th>
                  <Th p="2">Symbol Name</Th>
                  <Th p="2">Exchange</Th>
                  <Th p="2">Trading Symbol</Th>
                  <Th p="2">Product Type</Th>
                  <Th p="2">Transaction Type</Th>
                  <Th p="2">Order Type</Th>
                  <Th p="2">Quantity</Th>
                  {orderType === 'LIMIT' && <Th p="2">Price</Th>}
                  <Th p="2">Duration</Th>
                  <Th p="2">Variety</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td p="2">
                    <Input
                      value={positionData.client_details?.client_id}
                      isDisabled
                    />
                  </Td>
                  <Td p="2">
                    <Input value={positionData.symboltoken} isDisabled />
                  </Td>
                  <Td p="2">
                    <Input value={positionData.symbolname} isDisabled />
                  </Td>
                  <Td p="2">
                    <Input value={positionData.exchange} isDisabled />
                  </Td>
                  <Td p="2">
                    <Input value={positionData.tradingsymbol} isDisabled />
                  </Td>
                  <Td p="2">
                    <Input value={positionData.producttype} isDisabled />
                  </Td>
                  <Td p="2">
                    <Input value={transactionType} isDisabled />
                  </Td>
                  <Td p="2">
                    <ChakraSelect
                      value={orderType}
                      onChange={(e) => setOrderType(e.target.value)}
                    >
                      <option value="MARKET">MARKET</option>
                      <option value="LIMIT">LIMIT</option>
                    </ChakraSelect>
                  </Td>
                  <Td p="2">
                    <Input
                      type="number"
                      placeholder="Quantity"
                      size="sm"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </Td>
                  {orderType === 'LIMIT' && (
                    <Td p="2">
                      <Input
                        type="number"
                        placeholder="Price"
                        size="sm"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </Td>
                  )}
                  <Td p="2">
                    <Input value="DAY" isDisabled />
                  </Td>
                  <Td p="2">
                    <Input value="NORMAL" isDisabled />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr="3" onClick={handleExitPosition}>
              Exit
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExitPositionModal;
