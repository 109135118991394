import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Tooltip,
    Input,
    FormControl,
    FormLabel,
    Select,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../redux/actions/adminAction"; // Adjust the path to your action file

const UpdateUser = ({ user, fetchAllAdmin }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        role: "",
    });

    useEffect(() => {
        if (user) {
            setFormData({
                name: user.name || "",
                email: user.email || "",
                role: user.role || "",
            });
        }
    }, [user]);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            const response = await dispatch(updateUser(user.user_id, formData)); // Await the action
            if (response) {
                toast({
                    title: "User updated successfully.",
                    description: "The user details have been updated.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                fetchAllAdmin(); // Refresh the admin list after successful update
                setFormData({
                    email: '',
                    role: '',
                    name: '',
                }); // Reset the form data
            }
        } catch (error) {
            toast({
                title: "Failed to update user.",
                description: error.response?.data?.error || 
                             'An error occurred while updating the user. Please try again.',
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };
    

    return (
        <>
            <Tooltip label="Edit this user" fontSize="sm">
                <span onClick={onOpen}>
                    <FaEdit fontSize="22px" />
                </span>
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter name"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter email"
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>Role</FormLabel>
                            <Select
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                            >
                                <option value="Admin">Admin</option>
                                <option value="User">User</option>
                                <option value="Moderator">Moderator</option>
                            </Select>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" ml={3} onClick={handleSubmit}>
                            Update Changes
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UpdateUser;
