// reducers/adminReducer.js
import { GET_ORDER_POSITION_FAILURE, GET_ORDER_POSITION_REQUEST, GET_ORDER_POSITION_SUCCESS} from '../../redux/constants';
  
  const orderPositionReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ORDER_POSITION_REQUEST:
        return { ...state, loading: true, error: null };
      case GET_ORDER_POSITION_SUCCESS:
        return { ...state, loading: false,orderPosition: action.payload, error: null };
      case GET_ORDER_POSITION_FAILURE:
        return { ...state, loading: false,orderPosition: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default orderPositionReducer;