import { apiurl } from 'utils/config';
import {
  EXIT_POSITION_FAILURE,
  EXIT_POSITION_REQUEST,
  EXIT_POSITION_SUCCESS,
} from '../constants/index';
import api from '../axiosConfig';

export const exitPosition = (exitData) => async (dispatch) => {
  dispatch({ type: EXIT_POSITION_REQUEST });

  try {
    const response = await api.post(
      `${apiurl}api/trading/exit/position/`,
      exitData,
    );

    dispatch({ type: EXIT_POSITION_SUCCESS, payload: response.data });

    return { success: true, data: response.data }; // Return success response
  } catch (error) {
    const errorMessage = error.response ? error.response.data : 'Network error';

    dispatch({ type: EXIT_POSITION_FAILURE, payload: errorMessage });

    return { success: false, error: errorMessage }; // Return error response
  }
};
