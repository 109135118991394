import React, { useCallback, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../../redux/actions/accountAction';
import HoldingOrderBook from './components/HoldingOrderBook';
import { getOrderHolding } from '../../../redux/actions/holdingAction';

export default function OrderHolding() {
  const dispatch = useDispatch();
  const { orderHoldingData } = useSelector((state) => state.orderHolding);
  const { account } = useSelector((state) => state.account);
  const fetchAllHolding = useCallback(() => {
    dispatch(getOrderHolding());
    if (!account?.client_list) {
      dispatch(getAccount());
    }
  }, [dispatch, account?.client_list]);

  useEffect(() => {
    fetchAllHolding();
  }, [fetchAllHolding]);

  console.log('this is golding', orderHoldingData);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <HoldingOrderBook
        fetchAllHolding={fetchAllHolding}
        data={orderHoldingData?.holdings}
        client_list={account?.client_list}
      />
    </Box>
  );
}
