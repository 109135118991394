import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Select,
  Card,
  Input,
  Badge,
} from '@chakra-ui/react';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { downloadCSV } from 'utils/downloadCSV';
import { formatDate } from 'utils/formatDate';
import ViewUser from './ViewUser';
import { CloseIcon } from '@chakra-ui/icons';
import { FaSignOutAlt } from 'react-icons/fa';
import ExitPositionModal from './ExitPositionModal';

export default function PositionOrderBook({
  data,
  fetchAllOrder,
  client_list,
}) {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [clientNameFilter, setClientNameFilter] = useState('');
  const [socketData, setSocketData] = useState([]);
  const [positions, setPositions] = useState(data || []);
  const [netPrice, setNetPrice] = useState(null);
  const [pnl, setPnl] = useState(null);
  const [unrealised, setUnrealised] = useState(null);
  const [ltp, setLtp] = useState(null);

  // Sync with props if data changes
  useEffect(() => {
    setPositions(data);
  }, [data]);

  // useEffect(() => {
  //   const socket = new WebSocket('ws://127.0.0.1:8000/ws/positions/');

  //   socket.onopen = () => {
  //     console.log('WebSocket Connected');
  //   };

  //   socket.onmessage = (event) => {
  //     console.log('WebSocket Message:', event.data);
  //     try {
  //       const parsedData = JSON.parse(event.data); // Parse incoming WebSocket data (array of objects)
  //       console.log('WebSocket Message:', parsedData);

  //       if (Array.isArray(parsedData) && parsedData.length > 0) {
  //         setPositions((prevPosition) => {
  //           // Create a map of updated data for quick lookup
  //           const dataMap = new Map(
  //             parsedData.map((data) => [data.tradingsymbol, data]),
  //           );

  //           return prevPosition?.map((item) => {
  //             const updatedData = dataMap.get(item.tradingsymbol);

  //             if (updatedData) {
  //               return {
  //                 ...item,
  //                 pnl: updatedData.pnl || item.pnl,
  //                 netprice: updatedData.netprice || item.netprice,
  //                 ltp: updatedData.ltp || item.ltp,
  //                 unrealised: updatedData.unrealised || item.unrealised,
  //               };
  //             }

  //             return item; // Return unchanged item if no update is found
  //           });
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error parsing WebSocket message:', error);
  //     }
  //   };

  //   socket.onclose = () => {
  //     console.log('WebSocket Disconnected');
  //   };

  //   socket.onerror = (error) => {
  //     console.error('WebSocket Error:', error);
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  const handleSort = useCallback((field) => {
    setSortField(field);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  }, []);

  const handlePagination = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset page to 1 when rows per page changes
  }, []);

  const sortedData = useMemo(() => {
    if (!sortField) return positions;
    return [...positions].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });
  }, [positions, sortField, sortOrder]);

  const totalPages = Math.ceil(sortedData?.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedData?.slice(startIndex, endIndex);
  }, [sortedData, currentPage, itemsPerPage]);

  const handleDownload = () => {
    const csvData = paginatedData.map((item, index) => ({
      '#': index + 1 + (currentPage - 1) * itemsPerPage, // Row index
      'Client Id': item.client_details?.[0]?.client_id || 'N/A',
      Name: item.client_details?.[0]?.client_name || 'N/A',
      Symbol: item.symbolname || 'N/A',
      'Trading Symbol': item.tradingsymbol || 'N/A',
      'Strike Price': item.strikeprice || 'N/A',
      'Expiry Date': item.expirydate || 'N/A',
      'Lot Size': item.lotsize || 'N/A',
      'Sell Qty': item.sellqty || 'N/A',
      'Total Sell Value': item.totalsellvalue || 'N/A',
      'Net Price': item.netprice || 'N/A',
      PnL: item.pnl || 'N/A',
      Unrealised: item.unrealised || 'N/A',
      LTP: item.ltp || 'N/A',
      Status: item.status || 'N/A',
      'Update Time': formatDate(item?.updatetime),
      'Order Type': item.ordertype || 'N/A',
      'Transaction Type': item.transactiontype || 'N/A',
      'Product Type': item.producttype || 'N/A',
      Quantity: item.quantity || 'N/A',
      Price: item.price || 'N/A',
    }));

    downloadCSV(csvData, 'executed_order_data.csv');
  };

  const filteredData = paginatedData?.filter((item) => {
    const matchesClientName =
      clientNameFilter === '' ||
      item?.client_details[0]?.client_name
        ?.toLowerCase()
        .includes(clientNameFilter.toLowerCase());

    return matchesClientName;
  });

  console.log('socetv dta', positions);

  return (
    <Card
      flexDirection="column"
      w="100%"
      borderRadius={'12px'}
      padding={'12px'}
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          Positions
        </Text>
        <Flex gap="10px">
          {/* <Button variant="solid" colorScheme="messenger" isDisabled={true}>
            Exit
          </Button> */}
          <Button variant="solid" colorScheme="green" onClick={handleDownload}>
            Download
          </Button>
        </Flex>
      </Flex>

      {/* Filters */}
      <Flex px="25px" mb="16px" gap="20px">
        <Box>
          <Text fontWeight="600">Client Name</Text>
          <Select
            placeholder="Select client name"
            value={clientNameFilter}
            onChange={(e) => setClientNameFilter(e.target.value)}
            size="sm"
          >
            {client_list?.map((client) => (
              <option key={client.id} value={client.name}>
                {client.name}
              </option>
            ))}
          </Select>
        </Box>
      </Flex>

      <Box overflowX="auto">
        {filteredData === null || filteredData === undefined ? (
          <Text fontSize="lg" textAlign="center" mt="20px" color="gray.500">
            Loading Data...
          </Text>
        ) : filteredData.length > 0 ? (
          <Table variant="simple" color="gray.500" mb="24px" mt="24px">
            <Thead>
              <Tr>
                {[
                  { label: '#', key: '#' },
                  { label: 'Client Id', key: 'client_details[0].client_id' },
                  { label: 'Name', key: 'client_details[0].client_name' },
                  { label: 'Symbol', key: 'symbolname' },
                  { label: 'Trading Symbol', key: 'tradingsymbol' },
                  { label: 'Strike Price', key: 'strikeprice' },
                  { label: 'Expiry Date', key: 'expirydate' },
                  { label: 'Lot Size', key: 'lotsize' },
                  { label: 'Sell Qty', key: 'sellqty' },
                  { label: 'Total Sell Value', key: 'totalsellvalue' },
                  { label: 'Net Price', key: 'netprice' },
                  { label: 'PnL', key: 'pnl' },
                  { label: 'Unrealised', key: 'unrealised' },
                  { label: 'LTP', key: 'ltp' },
                  { label: 'Action', key: 'action' },
                ].map(({ label, key }) => (
                  <Th
                    key={key}
                    fontSize="sm"
                    color="black"
                    fontWeight="600"
                    whiteSpace="nowrap"
                    padding={'10px'}
                    cursor="pointer"
                    onClick={() => handleSort(key)}
                  >
                    {label}
                    {sortField === key &&
                      (sortOrder === 'asc' ? (
                        <MdArrowUpward />
                      ) : (
                        <MdArrowDownward />
                      ))}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {filteredData.map((item, index) => (
                <Tr
                  fontSize={'sm'}
                  key={index}
                  opacity={item?.netqty == 0 ? 0.5 : 1}
                  pointerEvents={item?.netqty == 0 ? 'none' : 'auto'}
                  backgroundColor={
                    item?.netqty == 0 ? 'gray.100' : 'transparent'
                  }
                >
                  <Td whiteSpace="nowrap" px="10px">
                    {index + 1 + (currentPage - 1) * itemsPerPage}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.client_details?.client_id || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.client_details?.client_name || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.symbolname || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.tradingsymbol || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.exchange === 'NSE'
                      ? 'N/A'
                      : item?.strikeprice || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.expirydate || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.lotsize || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.sellqty || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.totalsellvalue || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.netprice || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.pnl || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.unrealised || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px">
                    {item?.ltp || 'N/A'}
                  </Td>
                  <Td whiteSpace="nowrap" px="10px" display={'flex'} gap={3}>
                    <ViewUser data={item} />
                    <ExitPositionModal positionData={item} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text fontSize="lg" textAlign="center" mt="20px" color="gray.500">
            No Data Available
          </Text>
        )}
      </Box>

      <Flex justify="flex-end" align="center" mt="20px">
        <Flex gap="10px" align="center">
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            w="100px"
          >
            {[10, 20, 30, 50].map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </Select>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
        </Flex>
        <Flex gap="10px" ml="20px" alignItems="center">
          <Button
            onClick={() => handlePagination(currentPage - 1)}
            variant="outline"
            colorScheme="blue"
            isDisabled={currentPage === 1}
          >
            &lt;
          </Button>
          <Text fontWeight="bold" fontSize="md">
            {currentPage}
          </Text>
          <Button
            onClick={() => handlePagination(currentPage + 1)}
            variant="outline"
            colorScheme="blue"
            isDisabled={currentPage === totalPages}
          >
            &gt;
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
