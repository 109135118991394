import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
} from '../constants/index';
import api from '../axiosConfig';
import axios from 'axios';
import { apiurl } from '../../utils/config';

export const login = (email, password) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const response = await axios.post(`${apiurl}api/user/login/`, {
      email,
      password,
    });

    const { access, refresh } = response.data.token;

    setTokensInLocalStorage(access, refresh);
    const userPayload = response.data.user;
    localStorage.setItem('userDetails', JSON.stringify(userPayload));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: userPayload,
    });
    return userPayload;
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};

export const logout = () => {
  localStorage.clear();
  return {
    type: LOGOUT,
  };
};

// Action to fetch and set user data
export const getSelfData = async () => {
  const { data } = await api.get(`/users/self/`);

  const payload = data;

  localStorage.setItem('userDetails', JSON.stringify(payload));
  return payload;
};

// Store tokens in local storage
const setTokensInLocalStorage = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};
