// reducers/adminReducer.js
import {
  GET_ORDER_HOLDING_FAILURE,
  GET_ORDER_HOLDING_REQUEST,
  GET_ORDER_HOLDING_SUCCESS,
} from '../../redux/constants';

const orderHoldingReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_HOLDING_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ORDER_HOLDING_SUCCESS:
      return {
        ...state,
        loading: false,
        orderHoldingData: action.payload,
        error: null,
      };
    case GET_ORDER_HOLDING_FAILURE:
      return {
        ...state,
        loading: false,
        orderHoldingData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default orderHoldingReducer;
