import React from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";

const ViewAssignUser = ({ assignedClient, traders }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <FaEye fontSize={'24px'} color="blue" onClick={onOpen} />

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Assigned Clients of {traders.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {assignedClient && assignedClient.length > 0 ? (
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>ID</Th>
                                        <Th>Name</Th>
                                        <Th>Client ID</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {assignedClient.map((client) => (
                                        <Tr key={client.id}>
                                            <Td>{client.id}</Td>
                                            <Td>{client.name}</Td>
                                            <Td>{client.client_id}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <p>No assigned clients available.</p>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={onClose}
                            ml={3}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ViewAssignUser;
