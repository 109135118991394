import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdHome,
  MdSupervisedUserCircle,
  MdSettings,
  MdBook,
  MdTrendingUp,
  MdAssessment,
} from 'react-icons/md';
// Admin Imports
import AdminManagement from 'views/admin/adminManagement';
import { useSelector } from 'react-redux';
import AccountManagment from 'views/admin/accountManagement';
import OrderBook from 'views/admin/orderBook';
import OrderPosition from 'views/admin/orderPosition';
import OrderHolding from 'views/admin/orderHolding';

const useRoutes = () => {
  // Get user details from Redux store
  const userDetails = useSelector((state) => state.auth.userDetails);

  // Define routes dynamically
  const routes = [];

  if (userDetails?.role === 'SuperAdmin' || userDetails?.role === 'Admin') {
    routes.push({
      name: 'User Management',
      layout: '/admin',
      path: '/admin-panel',
      icon: (
        <Icon
          as={MdSupervisedUserCircle}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      component: <AdminManagement />,
    });
    routes.push({
      name: 'Config',
      layout: '/admin',
      path: '/account-management',
      icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
      component: <AccountManagment />,
    });
    routes.push({
      name: 'Order Book',
      layout: '/admin',
      path: '/order-book',
      icon: <Icon as={MdBook} width="20px" height="20px" color="inherit" />,
      component: <OrderBook />,
    });
    routes.push({
      name: 'Positions',
      layout: '/admin',
      path: '/order-position',
      icon: (
        <Icon as={MdTrendingUp} width="20px" height="20px" color="inherit" />
      ),
      component: <OrderPosition />,
    });
    routes.push({
      name: 'Holdings',
      layout: '/admin',
      path: '/order-holdings',
      icon: (
        <Icon as={MdAssessment} width="20px" height="20px" color="inherit" />
      ),
      component: <OrderHolding />,
    });
  }

  if (userDetails?.role === 'Trader') {
    routes.push({
      name: 'Config',
      layout: '/admin',
      path: '/admin-panel',
      icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
      component: <AccountManagment />,
    });
    routes.push({
      name: 'Order Book',
      layout: '/admin',
      path: '/order-book',
      icon: <Icon as={MdBook} width="20px" height="20px" color="inherit" />,
      component: <OrderBook />,
    });
    routes.push({
      name: 'Positions',
      layout: '/admin',
      path: '/order-position',
      icon: (
        <Icon as={MdTrendingUp} width="20px" height="20px" color="inherit" />
      ),
      component: <OrderPosition />,
    });
    routes.push({
      name: 'Holdings',
      layout: '/admin',
      path: '/order-holdings',
      icon: (
        <Icon as={MdAssessment} width="20px" height="20px" color="inherit" />
      ),
      component: <OrderHolding />,
    });
  }

  return routes;
};

export default useRoutes;
