export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const ADD_ADMIN_REQUEST = 'ADD_ADMIN_REQUEST';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE';

export const ADD_ASSIGN_REQUEST = 'ADD_ASSIGN_REQUEST';
export const ADD_ASSIGN_SUCCESS = 'ADD_ASSIGN_SUCCESS';
export const ADD_ASSIGN_FAILURE = 'ADD_ASSIGN_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const GET_ADMIN_REQUEST = 'GET_ADMIN_REQUEST';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE';

export const ADD_ACCOUNT_REQUEST = 'ADD_ACCOUNT_REQUEST';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';

export const GET_ORDER_BOOK_REQUEST = 'GET_ORDER_BOOK_REQUEST';
export const GET_ORDER_BOOK_SUCCESS = 'GET_ORDER_BOOK_SUCCESS';
export const GET_ORDER_BOOK_FAILURE = 'GET_ORDER_BOOK_FAILURE';

export const GET_ORDER_POSITION_REQUEST = 'GET_ORDER_POSITION_REQUEST';
export const GET_ORDER_POSITION_SUCCESS = 'GET_ORDER_POSITION_SUCCESS';
export const GET_ORDER_POSITION_FAILURE = 'GET_ORDER_POSITION_FAILURE';

export const GET_ORDER_HOLDING_REQUEST = 'GET_ORDER_HOLDING_REQUEST';
export const GET_ORDER_HOLDING_FAILURE = 'GET_ORDER_HOLDING_FAILURE';
export const GET_ORDER_HOLDING_SUCCESS = 'GET_ORDER_HOLDING_SUCCESS';

export const EXIT_POSITION_REQUEST = 'EXIT_POSITION_REQUEST';
export const EXIT_POSITION_SUCCESS = 'EXIT_POSITION_SUCCESS';
export const EXIT_POSITION_FAILURE = 'EXIT_POSITION_FAILURE';

export const EXIT_HOLDING_REQUEST = 'EXIT_HOLDING_REQUEST';
export const EXIT_HOLDING_SUCCESS = 'EXIT_HOLDING_SUCCESS';
export const EXIT_HOLDING_FAILURE = 'EXIT_HOLDING_FAILURE';
