import React, { useCallback, useState } from 'react';
import '../../../../assets/css/TagInput.css';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  Icon,
  useColorModeValue,
  ButtonGroup,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';
import { MdAdd, MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

const AddAdminForm = React.memo(
  ({ addAdmin, fetchAllAdmin, onClose }) => {
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const dispatch = useDispatch();
    const toast = useToast()
    const [show, setShow] = React.useState(false);

    const [formData, setFormData] = useState({
      email: '',
      password: '',
      password2: '',
      role: '',
      name: '',
    });

    const [errors, setErrors] = useState({
      email: '',
      password: '',
      password2: '',
      name: '',
      role: '',
    });

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));

      // Clear errors when user types
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }, []);

    const handleAddAdmin = () => {
      const { email, password, password2, name, role } = formData;
      let valid = true;
      let errorMessages = { email: '', password: '', password2: '', name: '', role: '' };

      // Email validation
      if (!email) {
        valid = false;
        errorMessages.email = 'Email is required.';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        valid = false;
        errorMessages.email = 'Invalid email format.';
      }

      // Password validation
      if (!password) {
        valid = false;
        errorMessages.password = 'Password is required.';
      }

      // Confirm Password validation
      if (!password2) {
        valid = false;
        errorMessages.password2 = 'Confirm Password is required.';
      } else if (password !== password2) {
        valid = false;
        errorMessages.password2 = 'Passwords do not match.';
      }

      // Name validation
      if (!name) {
        valid = false;
        errorMessages.name = 'Name is required.';
      }

      if (!role) {
        valid = false;
        errorMessages.role = 'Role is required.';
      }

      if (valid) {
        // Submit form data
        dispatch(addAdmin(formData)).then(response => {
          if (response) {
            toast({
              title: "Admin added successfully.",
              description: "The User has been added to the User list.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
            fetchAllAdmin();
            setFormData({
              email: '',
              password: '',
              password2: '',
              role: '',
              name: '',
            });
          }
        }).catch(error => {
          // Show error toast
          toast({
            title: "Failed to add user.",
            description: `${error.response.data.error || 'An error occurred while adding the admin. Please try again.'}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        setErrors(errorMessages);
      }
    };

    const handleClick = () => setShow(!show);

    return (
      <form>
        <Flex direction="column" w="100%" background="transparent">
          <FormControl>
            <SimpleGrid columns={2} gap="20px">
              <Box>
                <FormLabel
                  color={textColor}
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Email
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  variant="auth"
                  type="text"
                  placeholder="Email"
                  size="md"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <Text color="red.500" fontSize="sm">{errors.email}</Text>
                )}
              </Box>
              <Box>
                <FormLabel color={textColor} fontSize="sm" fontWeight="500">
                  Password
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    placeholder="Min. 8 characters"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <Icon
                      color="gray.400"
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <Text color="red.500" fontSize="sm">{errors.password}</Text>
                )}
              </Box>
              <Box>
                <FormLabel color={textColor} fontSize="sm" fontWeight="500">
                  Confirm Password
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    placeholder="Confirm Password"
                    name="password2"
                    value={formData.password2}
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <Icon
                      color="gray.400"
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.password2 && (
                  <Text color="red.500" fontSize="sm">{errors.password2}</Text>
                )}
              </Box>
              <Box>
                <FormLabel
                  color={textColor}
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Name
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  variant="auth"
                  type="text"
                  placeholder="Username"
                  size="md"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <Text color="red.500" fontSize="sm">{errors.name}</Text>
                )}
              </Box>
              <Box>
                <FormLabel
                  color={textColor}
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Role
                  <Text color={brandStars} display="inline">*</Text>
                </FormLabel>
                <Select
                  isRequired
                  variant="auth"
                  placeholder="Select Role"
                  size="md"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="Admin">Admin</option>
                  <option value="Trader">Trader</option>
                </Select>
                {errors.role && (
                  <Text color="red.500" fontSize="sm">{errors.role}</Text>
                )}
              </Box>
            </SimpleGrid>
          </FormControl>
        </Flex>
        <Flex justifyContent="center" mt="20px">
          <ButtonGroup spacing={4}>
            <Button
              onClick={handleAddAdmin} // Attach the handleAddBroker function
              fontSize="md"
              variant="brand"
              fontWeight="500"
            >
              Add <MdAdd />
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    );
  },
);

export default AddAdminForm;