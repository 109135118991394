import React, { useCallback, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { addAdmin, getAdmin } from '../../../redux/actions/adminAction';
import { getAccount } from '../../../redux/actions/accountAction';
import AdminTable from './components/AdminTable';

export default function BrokerManagement() {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.admin);
  const { account } = useSelector((state) => state.account);

  const fetchAllAdmin = useCallback(() => {
    dispatch(getAdmin());
  }, [dispatch]);

  const fetchAllAccount = useCallback(() => {
    dispatch(getAccount());
  }, [dispatch]);

  useEffect(() => {
    fetchAllAdmin();

    if (!account?.client_list) {
      fetchAllAccount();
    }
  }, [fetchAllAdmin, fetchAllAccount, account?.client_list]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <AdminTable
        fetchAllAdmin={fetchAllAdmin}
        addAdmin={addAdmin}
        data={admin?.profile_list}
        user={account?.client_list}
      />
    </Box>
  );
}
