import {jwtDecode} from 'jwt-decode';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import api from '../../redux/axiosConfig';
import { ThreeCircles } from 'react-loader-spinner';
import { Flex } from '@chakra-ui/react';

function ProtectedRoute({ children }) {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    auth();
  }, []);

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const res = await api.post('/user/token/refresh/', {
        refresh: refreshToken,
      });
      if (res.status === 200) {
        localStorage.setItem('accessToken', res.data.access);
        setIsAuthorized(true);
      } else {
        localStorage.clear();
        setIsAuthorized(false);
      }
    } catch (error) {
      localStorage.clear();
      setIsAuthorized(false);
    }
  };

  const auth = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      setIsAuthorized(false);
      return;
    }
    const decoded = jwtDecode(token);
    const tokenExpiration = decoded.exp;
    const now = Date.now() / 1000;

    if (tokenExpiration < now) {
      await refreshToken();
    } else {
      setIsAuthorized(true);
    }
  };

  if (isAuthorized === null) {
    return (
      <Flex
        w={'100%'}
        minH="100vh"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ThreeCircles
          visible={true}
          height="100"
          width="100"
          color="#4481EB"
          ariaLabel="three-circles-loading"
        />
      </Flex>
    );
  }

  return isAuthorized ? children : <Navigate to="/auth/sign-in" replace />;
}

export default ProtectedRoute;
