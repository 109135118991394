export const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle null or undefined dates

    const date = new Date(dateString);
    const options = {
        year: "numeric",
        month: "short", // 'Jan', 'Feb', etc.
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
    };

    return date.toLocaleString("en-US", options);
};
