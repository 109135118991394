// reducers/adminReducer.js
import { GET_ORDER_BOOK_FAILURE, GET_ORDER_BOOK_REQUEST, GET_ORDER_BOOK_SUCCESS} from '../../redux/constants';
  
  const orderBookReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_ORDER_BOOK_REQUEST:
        return { ...state, loading: true, error: null };
      case GET_ORDER_BOOK_SUCCESS:
        return { ...state, loading: false,orderBook: action.payload, error: null };
      case GET_ORDER_BOOK_FAILURE:
        return { ...state, loading: false,orderBook: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default orderBookReducer;
  