import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute';
import { useSelector } from 'react-redux';
import SignIn from 'views/auth/signIn';
// Chakra imports

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const { userDetails } = useSelector((state) => state.auth);

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/sign-in" element={<SignIn />} />
        <Route
          path="admin/*"
          element={
            <ProtectedRoute>
              <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </ProtectedRoute>
          }
        />
        <Route
          path="rtl/*"
          element={
            <ProtectedRoute>
              <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            userDetails ? (
              <Navigate to="/admin/admin-panel" replace />
            ) : (
              <Navigate to="/auth/sign-in" replace />
            )
          }
        />
      </Routes>
    </ChakraProvider>
  );
}
