// actions/brokerActions.js
import api from '../axiosConfig';
import { ADD_ACCOUNT_REQUEST, ADD_ACCOUNT_SUCCESS, ADD_ACCOUNT_FAILURE, GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE } from '../../redux/constants';
import { apiurl } from 'utils/config';

// Action to add a broker
export const addAccount = (accountData) => async (dispatch) => {
  dispatch({ type: ADD_ACCOUNT_REQUEST });
  try {
    const response = await api.post(`${apiurl}api/user/clients/`, accountData);
    dispatch({ type: ADD_ACCOUNT_SUCCESS, payload: response.data });
    return response.data; // Return the response data
  } catch (error) {
    const errorMessage = error.response ? error.response.data : "Network error";
    dispatch({
      type: ADD_ACCOUNT_FAILURE,
      payload: errorMessage,
    });
    throw error; // Throw the error so it can be caught by the caller
  }
};

// Action to fetch all brokers
export const getAccount = () => async (dispatch) => {
  dispatch({ type: GET_ACCOUNT_REQUEST });
  try {
    const response = await api.get(`${apiurl}api/user/clients/`);
    dispatch({ type: GET_ACCOUNT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: GET_ACCOUNT_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};

// Action to delete broker
// export const deleteBroker = (brokerId) => async (dispatch) => {
//   try {
//     const response = await api.delete(`/brokers/${brokerId}/`);
//     dispatch({ type: DELETE_BROKER_SUCCESS });
//   } catch (error) {
//     dispatch({
//       type: DELETE_BROKER_FAILURE,
//       payload: error.response ? error.response.data : 'Network error',
//     });
//   }
// };

//Update Broker
// export const updateBroker = (brokerId, updatedData) => async (dispatch) => {
//   dispatch({ type: UPDATE_BROKERS_REQUEST });
//   try {
//     const response = await api.put(`/brokers/${brokerId}/`, updatedData);
//     dispatch({ type: UPDATE_BROKERS_SUCCESS });
//   } catch (error) {
//     dispatch({ type: UPDATE_BROKERS_FAILURE, payload: error.message });
//   }
// };
