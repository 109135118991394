// reducers/adminReducer.js
import {
  EXIT_POSITION_REQUEST,
  EXIT_POSITION_SUCCESS,
  EXIT_POSITION_FAILURE,
} from '../../redux/constants';

const exitPositionReducer = (state = {}, action) => {
  switch (action.type) {
    case EXIT_POSITION_REQUEST:
      return { ...state, loading: true, error: null };
    case EXIT_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        exitPositionData: action.payload,
        error: null,
      };
    case EXIT_POSITION_FAILURE:
      return {
        ...state,
        loading: false,
        exitPositionData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default exitPositionReducer;
