import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Select as ChakraSelect,
  useToast,
  Box,
  Text,
  List,
  ListItem,
  Spinner,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { placeOrder } from '../../../../redux/actions/orderAction';
import { debounce } from 'lodash';
import { apiurl } from 'utils/config';
const demoClients = [
  { value: 1, label: 'Client A' },
  { value: 2, label: 'Client B' },
  { value: 3, label: 'Client C' },
];

const demoSymbols = [
  { symbol: 'SBIN-EQ', token: '3045' },
  { symbol: 'RELIANCE', token: '1234' },
  { symbol: 'TCS', token: '67890' },
];

const OrderPlaceModal = ({ isOpen, onClose, clientData }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [selectedClients, setSelectedClients] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [filteredSymbols, setFilteredSymbols] = useState([]);
  const [loadingSymbols, setLoadingSymbols] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedSymbolDetails, setSelectedSymbolDetails] = useState(null);

  // const handleInputClick = () => {
  //     setIsDropdownVisible(prev => !prev); // Toggle visibility
  // };

  // Fetch symbols from the API
  const fetchSymbols = async () => {
    setLoadingSymbols(true);
    try {
      const response = await fetch(`${apiurl}api/trading/symbol/`);
      const data = await response.json();
      setSymbols(data.data);
      setFilteredSymbols(data.data.slice(0, 20)); // Show first 20 by default
    } catch (error) {
      console.error('Error fetching symbols:', error);
    } finally {
      setLoadingSymbols(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchSymbols();
    }
  }, [isOpen]);

  // Debounced search handler
  // const handleSearch = useCallback(
  //     debounce((query) => {
  //         if (query.trim() === "") {
  //             setFilteredSymbols(symbols.slice(0, 20));
  //         } else {
  //             setFilteredSymbols(
  //                 symbols.filter((symbol) =>
  //                     symbol.toLowerCase().includes(query.toLowerCase())
  //                 )
  //             );
  //         }
  //     }, 300),
  //     [symbols]
  // );

  // // Handle search input change
  // const handleSearchInputChange = (e) => {
  //     const query = e.target.value;
  //     setSearchQuery(query);
  //     handleSearch(query); // Trigger debounced search
  // };

  // Handle symbol selection
  // const handleSelect = (i, symbol) => {
  //     console.log("index ", i);
  //     console.log("symbol ", symbol);

  //     handleOrderChange(i, "tradingsymbol", symbol);
  // };

  const [orders, setOrders] = useState([
    {
      client_id: [],
      tradingsymbol: '',
      symboltoken: '',
      variety: 'NORMAL',
      transactiontype: 'BUY',
      exchange: 'NSE',
      ordertype: 'LIMIT',
      producttype: 'INTRADAY',
      duration: 'DAY',
      price: '0',
      quantity: '0',
      squareoff: '0',
      stoploss: '0',
      searchQuery: '', // Add searchQuery per order
      isDropdownVisible: false, // Track visibility for each order's dropdown
      selectedClientDetails: [], // Store selected clients for this order
      selectedSymbolDetails: [], // Store selected symbols for this order
    },
  ]);

  // Handle Add Order
  const handleAddOrder = () => {
    setOrders([
      ...orders,
      {
        client_id: [],
        tradingsymbol: '',
        symboltoken: '',
        variety: 'NORMAL',
        transactiontype: 'BUY',
        exchange: 'NSE',
        ordertype: 'MARKET',
        producttype: '',
        duration: '',
        price: '0',
        quantity: 0,
        squareoff: 0,
        stoploss: 0,
        searchQuery: '', // Reset searchQuery for new order
        isDropdownVisible: false, // Set default dropdown visibility to false
        selectedClientDetails: [], // Store selected clients for this order
        selectedSymbolDetails: [], // Store selected symbols for this order
      },
    ]);
  };

  const handleSelectClient = (index, selected) => {
    // Extract selected client IDs and details
    const selectedIds = selected.map((option) => option.value);
    const selectedDetails = selected
      .map((option) => {
        const client = clientData.find((client) => client.id === option.value);
        return client
          ? {
              name: client.name,
              client_id: client.client_id,
              available_margin: client.available_margin,
            }
          : null;
      })
      .filter(Boolean);

    // Update the order with the selected client details
    const updatedOrders = [...orders];
    updatedOrders[index].client_id = selectedIds;
    updatedOrders[index].selectedClientDetails = selectedDetails; // Store selected client details for this order
    setOrders(updatedOrders); // Update orders state
  };

  // Combine selected clients across all orders for display
  const getCombinedSelectedClients = () => {
    const combinedClients = orders.reduce((acc, order) => {
      // Add selected clients for each order
      order.selectedClientDetails?.forEach((client) => {
        if (
          !acc.some(
            (existingClient) => existingClient.client_id === client.client_id,
          )
        ) {
          acc.push(client);
        }
      });
      return acc;
    }, []);
    return combinedClients;
  };

  // Handle Input Click (Toggle dropdown visibility for specific order)
  const handleInputClick = (index) => {
    const updatedOrders = [...orders];
    updatedOrders[index].isDropdownVisible =
      !updatedOrders[index].isDropdownVisible;
    setOrders(updatedOrders);
  };

  // Handle Search Input Change
  const handleSearchInputChange = (e, index) => {
    const query = e.target.value;
    const updatedOrders = [...orders];
    updatedOrders[index].searchQuery = query; // Update searchQuery for this order
    setOrders(updatedOrders);
    handleSearch(query, index); // Trigger debounced search for this order
  };

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((query, index) => {
      if (query.trim() === '') {
        setFilteredSymbols(symbols.slice(0, 20)); // Reset filteredSymbols
      } else {
        setFilteredSymbols(
          symbols.filter((symbol) =>
            symbol.toLowerCase().includes(query.toLowerCase()),
          ),
        );
      }
    }, 300),
    [symbols],
  );

  // Handle symbol selection
  const handleSelect = (i, symbol, index) => {
    handleOrderChange(index, 'tradingsymbol', symbol);
    const updatedOrders = [...orders];
    updatedOrders[index].searchQuery = symbol; // Update searchQuery with selected symbol
    updatedOrders[index].isDropdownVisible = false; // Hide the dropdown after selection
    setOrders(updatedOrders);
  };

  // Handle Order Change
  const handleOrderChange = async (index, field, value) => {
    const updatedOrders = [...orders];
    updatedOrders[index][field] = value;

    // Auto-select symboltoken based on tradingsymbol
    if (field === 'tradingsymbol') {
      const selectedSymbol = symbols.find((item) => item === value);
      if (!selectedSymbol) {
        console.error(`Symbol "${value}" not found in symbols.`);
      }
      try {
        const symbolData = await fetchSymbolDetails(value);
        if (symbolData && symbolData.symbolToken) {
          updatedOrders[index].symboltoken = symbolData.symbolToken;
          updatedOrders[index].exchange = symbolData.exchange;
          if (symbolData.exchange == 'NSE') {
            updatedOrders[index].producttype = 'DELIVERY';
            updatedOrders[index].ordertype = 'LIMIT';
            updatedOrders[index].duration = 'DAY';
          } else if (symbolData.exchange == 'NFO') {
            updatedOrders[index].producttype = 'CARRYFORWARD';
            updatedOrders[index].ordertype = 'LIMIT';
            updatedOrders[index].duration = 'DAY';
          }
          updatedOrders[index].quantity = symbolData.lotsize;
          updatedOrders[index].selectedSymbolDetails = {
            tradingsymbol: value,
            ltp: symbolData.ltp || 'N/A',
            lot: symbolData.lotsize || 'N/A',
            close: symbolData.close || 'N/A',
            buy: symbolData.depth?.buy?.[0] || {
              price: 'N/A',
              quantity: 'N/A',
              orders: 'N/A',
            },
            sell: symbolData.depth?.sell?.[0] || {
              price: 'N/A',
              quantity: 'N/A',
              orders: 'N/A',
            },
          };
        } else {
          updatedOrders[index].symboltoken = '';
          console.error(`Symbol token not found for "${value}".`);
        }
      } catch (error) {
        console.error('Error fetching symbol details:', error);
        updatedOrders[index].symboltoken = '';
      }

      // Update searchQuery to reflect the selected symbol
      updatedOrders[index].searchQuery = value; // Update the input field with the selected symbol
    }

    // Reset price if ordertype changes
    if (field === 'ordertype' && value === 'MARKET') {
      updatedOrders[index].price = '0';
    }

    setOrders(updatedOrders);
  };

  const fetchSymbolDetails = async (symbol) => {
    try {
      const response = await fetch(`${apiurl}api/trading/ltp/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tradingsymbol: symbol }),
      });
      const data = await response.json();
      setSelectedSymbolDetails(data); // Set the fetched details to state
      return data; // Return fetched data
    } catch (error) {
      console.error('Error fetching symbol details:', error);
    }
  };

  const handleSaveOrders = async () => {
    try {
      // Call the placeOrder action and get the response
      const response = await dispatch(placeOrder(orders));

      // Loop through the results array to display appropriate toasts
      if (response?.results?.length) {
        response.results.forEach((result) => {
          if (
            result.message === 'Order placed successfully' &&
            result.order_id
          ) {
            toast({
              title: 'Success',
              description: `Order for client ID ${result.client_id} placed successfully. Order ID: ${result.order_id}`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: 'Error',
              description: `Order for client ID ${result.client_id} failed. Reason: ${result.message}`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        });
      } else {
        // If no results are returned, show a generic error
        toast({
          title: 'Error',
          description: 'No response data received.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset orders, selectedClients, and selectedSymbolDetails
      // Option 1: Reset the entire orders array and clear the selected client and symbol details
      setOrders([]);
      setSelectedClients([]); // Clear selected clients
      setSelectedSymbolDetails(null); // Clear selected symbol details

      // Option 2: Reset only specific fields in the orders array and clear selected clients/symbols
      setOrders(
        orders.map((order) => ({
          ...order,
          client_id: [],
          searchQuery: '',
          symboltoken: '',
          exchange: '',
          price: '',
          // Reset any other fields as needed
          selectedSymbolDetails: null, // Clear symbol details for the order
        })),
      );

      // Close the modal after handling toasts
      onClose();
    } catch (error) {
      // Display a generic error toast if the API call fails
      toast({
        title: 'Error',
        description: error.response?.data || 'Failed to place orders.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Place Order</ModalHeader>
        <ModalBody>
          {getCombinedSelectedClients().length > 0 && (
            <Box
              mt="4"
              p="4"
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="sm"
            >
              <Text fontWeight="bold" mb="2">
                Selected Client Details:
              </Text>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th p={'0px'}>Name</Th>
                    <Th p={'0px'}>Client ID</Th>
                    <Th p={'0px'}>Available Margin</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getCombinedSelectedClients().map((client, idx) => (
                    <Tr key={`${client.client_id}-${idx}`}>
                      {' '}
                      {/* Unique key using client_id + index */}
                      <Td p={'0px'}>{client.name}</Td>
                      <Td p={'0px'}>{client.client_id}</Td>
                      <Td p={'0px'}>{client.available_margin}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}

          <Box mt="4" p="4" borderWidth="1px" borderRadius="lg" boxShadow="sm">
            <Text fontWeight="bold" mb="2">
              Selected Symbol Details:
            </Text>

            {/* Check if there are any selectedSymbolDetails in the orders array */}
            {orders.some((order) => order.selectedSymbolDetails) ? (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th p={'0px'}>Symbol</Th>
                    <Th p={'0px'}>LTP</Th>
                    <Th p={'0px'}>Lot</Th>
                    <Th p={'0px'}>Close</Th>
                    <Th p={'0px'}>Bid</Th>
                    <Th p={'0px'}>Qty</Th>
                    <Th p={'0px'}>Orders</Th>
                    <Th p={'0px'}>Ask</Th>
                    <Th p={'0px'}>Qty</Th>
                    <Th p={'0px'}>Orders</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orders.map((order, index) => {
                    const selectedSymbolDetails = order.selectedSymbolDetails;

                    return selectedSymbolDetails ? (
                      <Tr key={index}>
                        <Td p={'0px'}>{selectedSymbolDetails.tradingsymbol}</Td>
                        <Td p={'0px'}>{selectedSymbolDetails.ltp || 'N/A'}</Td>
                        <Td p={'0px'}>{selectedSymbolDetails.lot || 'N/A'}</Td>
                        <Td p={'0px'}>
                          {selectedSymbolDetails.close || 'N/A'}
                        </Td>
                        {/* Buy Fields */}
                        <Td p={'0px'}>
                          {selectedSymbolDetails.buy?.price ?? 'N/A'}
                        </Td>
                        <Td p={'0px'}>
                          {selectedSymbolDetails.buy?.quantity ?? 'N/A'}
                        </Td>
                        <Td p={'0px'}>
                          {selectedSymbolDetails.buy?.orders ?? 'N/A'}
                        </Td>
                        {/* Sell Fields */}
                        <Td p={'0px'}>
                          {selectedSymbolDetails.sell?.price ?? 'N/A'}
                        </Td>
                        <Td p={'0px'}>
                          {selectedSymbolDetails.sell?.quantity ?? 'N/A'}
                        </Td>
                        <Td p={'0px'}>
                          {selectedSymbolDetails.sell?.orders ?? 'N/A'}
                        </Td>
                      </Tr>
                    ) : null;
                  })}
                </Tbody>
              </Table>
            ) : (
              <Text>No symbol details available</Text>
            )}
          </Box>

          <Table variant="simple">
            <Thead>
              <Tr>
                {[
                  'Client',
                  'Trading Symbol',
                  'Transaction Type',
                  // 'Exchange',
                  'Order Type',
                  'Product Type',
                  'Duration',
                  'Quantity',
                  ...(orders.some((order) => order.ordertype === 'LIMIT')
                    ? ['Price']
                    : []),
                ].map((field) => (
                  <Th key={field} p="2">
                    {field}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {orders.map((order, index) => (
                <Tr key={index}>
                  <Td p="2" key={index}>
                    <Select
                      isMulti
                      options={[
                        { value: 'select_all', label: 'Select All' }, // Add "Select All" option
                        ...(Array.isArray(clientData)
                          ? clientData.map((client) => ({
                              value: client.id,
                              label: client.name,
                            }))
                          : []),
                      ]}
                      value={
                        Array.isArray(order.client_id) &&
                        Array.isArray(clientData) &&
                        order.client_id.length === clientData.length
                          ? [{ value: 'select_all', label: 'Select All' }]
                          : Array.isArray(order.client_id)
                          ? order.client_id
                              .map((id) => {
                                const client = clientData?.find(
                                  (client) => client.id === id,
                                );
                                return client
                                  ? { value: client.id, label: client.name }
                                  : null;
                              })
                              .filter(Boolean)
                          : []
                      }
                      onChange={(selected) => {
                        if (
                          selected.some(
                            (option) => option.value === 'select_all',
                          )
                        ) {
                          handleSelectClient(
                            index,
                            clientData.map((client) => ({
                              value: client.id,
                              label: client.name,
                            })),
                          ); // Select all clients
                        } else {
                          handleSelectClient(index, selected);
                        }
                      }}
                      placeholder="Select Clients"
                    />
                  </Td>
                  <Td p="2" key={index}>
                    <Input
                      placeholder={
                        loadingSymbols ? 'Loading...' : 'Search Symbol'
                      }
                      value={order.searchQuery} // Bind the value to the specific order's searchQuery
                      onChange={(e) => handleSearchInputChange(e, index)} // Handle input change per order
                      disabled={loadingSymbols}
                      onClick={() => handleInputClick(index)} // Toggle dropdown visibility for this order
                    />
                    {loadingSymbols ? (
                      <Spinner size="sm" mt="2" />
                    ) : (
                      order.isDropdownVisible && ( // Only show the dropdown if visibility is true for this order
                        <List
                          position="absolute"
                          zIndex="10"
                          bg="white"
                          border="1px solid #ccc"
                          borderRadius="md"
                          maxHeight="200px"
                          overflowY="auto"
                          mt="2"
                        >
                          {filteredSymbols.map((symbol, i) => (
                            <ListItem
                              key={i}
                              p="2"
                              _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                              onClick={() => handleSelect(i, symbol, index)} // Pass index to handleSelect
                            >
                              {symbol}
                            </ListItem>
                          ))}
                          {filteredSymbols.length === 0 && (
                            <ListItem p="2" textAlign="center" color="gray.500">
                              No results found
                            </ListItem>
                          )}
                        </List>
                      )
                    )}
                  </Td>

                  <Td p="2">
                    <ChakraSelect
                      value={order.transactiontype}
                      onChange={(e) =>
                        handleOrderChange(
                          index,
                          'transactiontype',
                          e.target.value,
                        )
                      }
                    >
                      <option value="BUY">BUY</option>
                      <option value="SELL">SELL</option>
                    </ChakraSelect>
                  </Td>
                  <Td p="2">
                    <ChakraSelect
                      value={order.ordertype}
                      onChange={(e) =>
                        handleOrderChange(index, 'ordertype', e.target.value)
                      }
                    >
                      <option value="MARKET">MARKET</option>
                      <option value="LIMIT">LIMIT</option>
                    </ChakraSelect>
                  </Td>
                  <Td p="2">
                    <ChakraSelect
                      placeholder="Select Product Type"
                      value={order.producttype}
                      onChange={(e) =>
                        handleOrderChange(index, 'producttype', e.target.value)
                      }
                    >
                      <option value="DELIVERY">DELIVERY</option>
                      <option value="CARRYFORWARD">CARRYFORWARD</option>
                      <option value="MARGIN">MARGIN</option>
                      <option value="INTRADAY">INTRADAY</option>
                      <option value="BO">BO</option>
                    </ChakraSelect>
                  </Td>

                  <Td p="2">
                    <ChakraSelect
                      placeholder="Select Duration"
                      value={order.duration}
                      onChange={(e) =>
                        handleOrderChange(index, 'duration', e.target.value)
                      }
                    >
                      <option value="DAY">DAY</option>
                      <option value="IOC">IOC</option>
                    </ChakraSelect>
                  </Td>
                  <Td p="2">
                    <Input
                      type="number"
                      value={order.quantity}
                      onChange={(e) =>
                        handleOrderChange(index, 'quantity', e.target.value)
                      }
                      placeholder="Quantity"
                      size="sm"
                    />
                  </Td>
                  {order.ordertype === 'LIMIT' && (
                    <Td p="2">
                      <Input
                        type="number"
                        value={order.price}
                        onChange={(e) =>
                          handleOrderChange(index, 'price', e.target.value)
                        }
                        placeholder="Price"
                        size="sm"
                      />
                    </Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Button mt="10px" colorScheme="blue" onClick={handleAddOrder}>
            Add More Order
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr="3" onClick={handleSaveOrders}>
            Execute
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderPlaceModal;
