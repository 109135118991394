import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import adminReducer from './reducers/adminReducer';
import accountReducer from './reducers/accountReducer';
import orderBookReducer from './reducers/orderBookReducer';
import orderPositionReducer from './reducers/positionReducer';
import orderHoldingReducer from './reducers/holdingReducer';
import exitPositionReducer from './reducers/exitPositionReducer';

const reducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  account: accountReducer,
  orderBook: orderBookReducer,
  orderPosition: orderPositionReducer,
  orderHolding: orderHoldingReducer,
  exitPosition: exitPositionReducer,
});

// Retrieve userDetails from localStorage if it exists
const userInfoFromStorage = localStorage.getItem('userDetails')
  ? JSON.parse(localStorage.getItem('userDetails'))
  : null;

const initialState = {
  auth: { userDetails: userInfoFromStorage || {} }, // Fallback to an empty object if no data in localStorage
};
const store = configureStore({
  reducer,
  preloadedState: initialState, // Pass initial state to Redux store
});

export default store;
