// actions/brokerActions.js
import api from '../axiosConfig';
import {
  GET_ORDER_HOLDING_REQUEST,
  GET_ORDER_HOLDING_SUCCESS,
  GET_ORDER_HOLDING_FAILURE,
} from '../../redux/constants';
import { apiurl } from 'utils/config';

// Action to fetch all brokers
export const getOrderHolding = () => async (dispatch) => {
  dispatch({ type: GET_ORDER_HOLDING_REQUEST });
  try {
    const response = await api.get(`${apiurl}api/trading/holding/`);
    dispatch({ type: GET_ORDER_HOLDING_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: GET_ORDER_HOLDING_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};
