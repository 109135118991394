// reducers/adminReducer.js
import { ADD_ADMIN_REQUEST, GET_ADMIN_FAILURE, GET_ADMIN_REQUEST, GET_ADMIN_SUCCESS } from '../../redux/constants';
import { ADD_ADMIN_SUCCESS } from '../../redux/constants';
import { ADD_ADMIN_FAILURE } from '../../redux/constants';
  
  const adminReducer = (state = {}, action) => {
    switch (action.type) {
      case ADD_ADMIN_REQUEST:
        return { ...state, loading: true, error: null };
      case ADD_ADMIN_SUCCESS:
        return { ...state, loading: false,adminAdd: action.payload, error: null };
      case ADD_ADMIN_FAILURE:
        return { ...state, loading: false,adminAdd: [], error: action.payload };
      case GET_ADMIN_REQUEST:
        return { ...state, loading: true, error: null };
      case GET_ADMIN_SUCCESS:
        return { ...state, loading: false,admin: action.payload, error: null };
      case GET_ADMIN_FAILURE:
        return { ...state, loading: false,admin: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default adminReducer;
  