// actions/brokerActions.js
import api from '../axiosConfig';
import { GET_ORDER_POSITION_REQUEST, GET_ORDER_POSITION_SUCCESS, GET_ORDER_POSITION_FAILURE } from '../../redux/constants';
import { apiurl } from 'utils/config';

// Action to fetch all brokers
export const getOrderPosition = () => async (dispatch) => {
  dispatch({ type: GET_ORDER_POSITION_REQUEST });
  try {
    const response = await api.get(`${apiurl}api/trading/positions/`);
    dispatch({ type: GET_ORDER_POSITION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: GET_ORDER_POSITION_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};
