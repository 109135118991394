import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Select,
  Card,
  Input,
  Badge,
} from '@chakra-ui/react';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import React, { useCallback, useState, useMemo } from 'react';
import { downloadCSV } from 'utils/downloadCSV';
import { formatDate } from 'utils/formatDate';
import ViewUser from './ViewUser';

export default function ExecutedOrderBook({
  data,
  fetchAllOrder,
  client_list,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [clientNameFilter, setClientNameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [transactionTypeFilter, setTransactionTypeFilter] = useState('');

  const handleSort = useCallback((field) => {
    setSortField(field);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  }, []);

  const handlePagination = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset page to 1 when rows per page changes
  }, []);

  const sortedData = useMemo(() => {
    if (!sortField) return data;
    return [...data].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });
  }, [data, sortField, sortOrder]);

  const totalPages = Math.ceil(sortedData?.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedData?.slice(startIndex, endIndex);
  }, [sortedData, currentPage, itemsPerPage]);

  const handleDownload = () => {
    const csvData = paginatedData.map((item) => ({
      '#': item.id, // Assuming item.id maps to "#"
      'Client Id': item.client_details?.client_id || 'None',
      Name: item.client_details?.client_name || 'None',
      Status: item?.status || 'None',
      'Update Time': item.updatetime || 'None',
      'Trading Symbol': item.tradingsymbol || 'None',
      'Order Type': item.ordertype || 'None',
      'Transaction Type': item.transactiontype || 'None',
      'Product Type': item.producttype || 'None',
      Quantity: item.quantity || 'None',
      Price: item.price || 'None',
    }));
    downloadCSV(csvData, 'executed_order_data.csv');
  };

  const filteredData = paginatedData?.filter((item) => {
    const matchesClientName =
      clientNameFilter === '' ||
      item?.client_details?.client_name
        ?.toLowerCase()
        .includes(clientNameFilter.toLowerCase());
    const matchesStatus =
      statusFilter === '' ||
      item?.status?.toLowerCase() === statusFilter.toLowerCase();
    const matchesTransactionType =
      transactionTypeFilter === '' ||
      item?.transactiontype?.toLowerCase() ===
        transactionTypeFilter.toLowerCase();

    return matchesClientName && matchesStatus && matchesTransactionType;
  });

  //   console.log('this is data', filteredData);

  return (
    <Card
      flexDirection="column"
      w="100%"
      borderRadius={'12px'}
      padding={'12px'}
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text color="black" fontSize="22px" fontWeight="700" lineHeight="100%">
          👥 Executed Order Book
        </Text>
        <Flex gap="10px">
          <Button variant="solid" colorScheme="green" onClick={handleDownload}>
            Download
          </Button>
        </Flex>
      </Flex>

      {/* Filters */}
      <Flex px="25px" mb="16px" gap="20px">
        <Box>
          <Text fontWeight="600">Client Name</Text>
          <Select
            placeholder="Select client name"
            value={clientNameFilter}
            onChange={(e) => setClientNameFilter(e.target.value)}
            size="sm"
          >
            {client_list?.map((client) => (
              <option key={client.id} value={client.name}>
                {client.name}
              </option>
            ))}
          </Select>
        </Box>
        <Box>
          <Text fontWeight="600">Status</Text>
          <Select
            placeholder="Filter by status"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            size="sm"
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Pending">Pending</option>
            <option value="Rejected">Rejected</option>
          </Select>
        </Box>
        <Box>
          <Text fontWeight="600">Transaction Type</Text>
          <Select
            placeholder="Filter by transaction type"
            value={transactionTypeFilter}
            onChange={(e) => setTransactionTypeFilter(e.target.value)}
            size="sm"
          >
            <option value="Buy">Buy</option>
            <option value="Sell">Sell</option>
          </Select>
        </Box>
      </Flex>

      <Box overflowX="auto">
        <Table variant="simple" color="gray.500" mb="24px" mt="24px">
          <Thead>
            <Tr>
              {[
                { label: '#', key: '#' },
                { label: 'Client Id', key: 'client_details[0].client_id' },
                { label: 'Name', key: 'client_details[0].client_name' },
                { label: 'Status', key: 'status' },
                { label: 'Update Time', key: 'updatetime' },
                { label: 'Trading Symbol', key: 'tradingsymbol' },
                { label: 'Order Type', key: 'ordertype' },
                { label: 'Transaction Type', key: 'transactiontype' },
                { label: 'Product Type', key: 'producttype' },
                { label: 'Quantity', key: 'quantity' },
                { label: 'Price', key: 'price' },
                { label: 'Action', key: 'action' },
              ].map(({ label, key }) => (
                <Th
                  key={key}
                  fontSize="sm"
                  color="black"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  padding={'10px'}
                  cursor="pointer"
                  onClick={() => handleSort(key)}
                >
                  {label}
                  {sortField === key &&
                    (sortOrder === 'asc' ? (
                      <MdArrowUpward />
                    ) : (
                      <MdArrowDownward />
                    ))}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.map((item, index) => (
              <Tr fontSize={'sm'} key={index}>
                <Td whiteSpace="nowrap" px="10px">
                  {index + 1 + (currentPage - 1) * itemsPerPage}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.client_details?.client_id || 'N/A'}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.client_details?.client_name || 'N/A'}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  <Badge
                    colorScheme={
                      item?.status === 'rejected'
                        ? 'red'
                        : item?.status === 'pending'
                        ? 'yellow'
                        : item?.status === 'accepted'
                        ? 'green'
                        : 'gray'
                    }
                  >
                    {item?.status || 'N/A'}
                  </Badge>
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {formatDate(item?.updatetime)}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.tradingsymbol}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.ordertype}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.transactiontype}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.producttype}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.quantity}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  {item?.price}
                </Td>
                <Td whiteSpace="nowrap" px="10px">
                  <ViewUser data={item} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="flex-end" align="center" mt="20px">
        <Flex gap="10px" align="center">
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            w="100px"
          >
            {[10, 20, 30, 50].map((count) => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </Select>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
        </Flex>
        <Flex gap="10px" ml="20px" alignItems="center">
          <Button
            onClick={() => handlePagination(currentPage - 1)}
            variant="outline"
            colorScheme="blue"
            isDisabled={currentPage === 1}
          >
            &lt;
          </Button>
          <Text fontWeight="bold" fontSize="md">
            {currentPage}
          </Text>
          <Button
            onClick={() => handlePagination(currentPage + 1)}
            variant="outline"
            colorScheme="blue"
            isDisabled={currentPage === totalPages}
          >
            &gt;
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
