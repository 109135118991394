import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { deleteUser } from "../../../../redux/actions/adminAction";
import { MdDeleteOutline } from 'react-icons/md';

const DeleteUser = ({ userId, userName, fetchAllAdmin }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const toast = useToast();

    const handleDelete = () => {
        setLoading(true);
        try {
            dispatch(deleteUser(userId)).then(response => {
                toast({
                    title: 'User deleted',
                    description: `${userName} has been successfully deleted.`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                fetchAllAdmin();
            });
        } catch (error) {
            toast({
                title: 'Error deleting user',
                description: error.message || 'Something went wrong.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <MdDeleteOutline fontSize={'26px'} color='red' onClick={onOpen} />

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Are you sure you want to delete <strong>{userName}</strong>?
                            This action cannot be undone.
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose} isDisabled={loading}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={handleDelete}
                            isLoading={loading}
                            ml={3}
                        >
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeleteUser;
