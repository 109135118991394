import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { addAdmin, getAdmin } from '../../../redux/actions/adminAction';
import { getAccount } from '../../../redux/actions/accountAction';
import AccountTable from './components/AccountTable';

export default function AccountManagment() {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.account);
  const {userDetails} = useSelector((state) => state.auth);

  const fetchAllAccount = useCallback(() => {
    dispatch(getAccount());
  }, [dispatch]);

  useEffect(() => {
    fetchAllAccount();
  }, [fetchAllAccount]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <AccountTable
        fetchAllAccount={fetchAllAccount}
        userDetails={userDetails}
        addAdmin={addAdmin}
        data={account?.client_list}
      />
    </Box>
  );
}
