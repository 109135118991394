import React from "react";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Table,
    Tbody,
    Tr,
    Td,
    Th,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import { formatDate } from "../../../../utils/formatDate"; // Adjust the path for your utility function

const ViewUser = ({ data }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <FaEye fontSize={"24px"} color="blue" onClick={onOpen} />

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Account Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {data ? (
                            <Table variant="simple" size="sm">
                                <Tbody>
                                    {Object.entries(data).map(([key, value]) => (
                                        <Tr key={key}>
                                            <Th textTransform="capitalize">{key.replace(/_/g, " ")}</Th>
                                            <Td>
                                                {key.includes("created_at") || key.includes("updated_at")
                                                    ? formatDate(value) // Format dates
                                                    : value?.toString() || "N/A"}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        ) : (
                            <p>No data available.</p>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={onClose} ml={3}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ViewUser;
