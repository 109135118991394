// actions/brokerActions.js
import api from '../axiosConfig';
import { GET_ORDER_BOOK_REQUEST, GET_ORDER_BOOK_SUCCESS, GET_ORDER_BOOK_FAILURE } from '../../redux/constants';
import { apiurl } from 'utils/config';

// Action to fetch all brokers
export const getOrderBook = () => async (dispatch) => {
  dispatch({ type: GET_ORDER_BOOK_REQUEST });
  try {
    const response = await api.get(`${apiurl}api/trading/order-list/`);
    dispatch({ type: GET_ORDER_BOOK_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: GET_ORDER_BOOK_FAILURE,
      payload: error.response ? error.response.data : 'Network error',
    });
  }
};

// Action to delete broker
// export const deleteBroker = (brokerId) => async (dispatch) => {
//   try {
//     const response = await api.delete(`/brokers/${brokerId}/`);
//     dispatch({ type: DELETE_BROKER_SUCCESS });
//   } catch (error) {
//     dispatch({
//       type: DELETE_BROKER_FAILURE,
//       payload: error.response ? error.response.data : 'Network error',
//     });
//   }
// };

//Update Broker
// export const updateBroker = (brokerId, updatedData) => async (dispatch) => {
//   dispatch({ type: UPDATE_BROKERS_REQUEST });
//   try {
//     const response = await api.put(`/brokers/${brokerId}/`, updatedData);
//     dispatch({ type: UPDATE_BROKERS_SUCCESS });
//   } catch (error) {
//     dispatch({ type: UPDATE_BROKERS_FAILURE, payload: error.message });
//   }
// };
