import React, { useCallback, useState } from 'react';
import '../../../../assets/css/TagInput.css';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  Icon,
  useColorModeValue,
  ButtonGroup,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';
import { MdAdd, MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { addAccount } from '../../../../redux/actions/accountAction';

const AddAdminForm = React.memo(
  ({ addAdmin, fetchAllAdmin, onClose }) => {
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const dispatch = useDispatch();
    const toast = useToast()
    const [show, setShow] = React.useState(false);

    const [formData, setFormData] = useState({
      api_key: '',
      client_id: '',
      client_password: '',
      totp_key: '',
    });

    const [errors, setErrors] = useState({
      api_key: '',
      client_id: '',
      totp_key: '',
      client_password: '',
    });

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));

      // Clear errors when user types
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }, []);

    const handleAddAdmin = () => {
      const { api_key, totp_key, client_id, name, client_password } = formData;
      let valid = true;
      let errorMessages = { api_key: '', totp_key: '', client_id: '', name: '', client_password: '' };

      // api_key validation
      if (!api_key) {
        valid = false;
        errorMessages.api_key = 'api_key is required.';
      }

      // Password validation
      if (!totp_key) {
        valid = false;
        errorMessages.totp_key = 'Password is required.';
      }

      // Confirm Password validation
      if (!client_id) {
        valid = false;
        errorMessages.client_id = 'Confirm Password is required.';
      }

      if (!client_password) {
        valid = false;
        errorMessages.client_password = 'client_password is required.';
      }

      if (valid) {
        // Submit form data
        dispatch(addAccount(formData)).then(response => {
          if (response) {
            toast({
              title: "Admin added successfully.",
              description: "The User has been added to the User list.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            onClose();
            fetchAllAdmin();
            setFormData({
              api_key: '',
              totp_key: '',
              client_id: '',
              client_password: '',
              name: '',
            });
          }
        }).catch(error => {
          // Show error toast
          toast({
            title: "Failed to add user.",
            description: `${error.response?.data.error || 'An error occurred while adding the admin. Please try again.'}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        setErrors(errorMessages);
      }
    };

    const handleClick = () => setShow(!show);

    return (
      <form>
        <Flex direction="column" w="100%" background="transparent">
          <FormControl>
            <SimpleGrid columns={2} gap="20px">
              <Box>
                <FormLabel
                  color={textColor}
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Api Key
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  variant="auth"
                  type="text"
                  placeholder="api_key"
                  size="md"
                  name="api_key"
                  value={formData.api_key}
                  onChange={handleInputChange}
                />
                {errors.api_key && (
                  <Text color="red.500" fontSize="sm">{errors.api_key}</Text>
                )}
              </Box>
              <Box>
                <FormLabel color={textColor} fontSize="sm" fontWeight="500">
                  Cliend Id
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired
                    type='text'
                    variant="auth"
                    placeholder="Min. 8 characters"
                    name="client_id"
                    value={formData.client_id}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {errors.client_id && (
                  <Text color="red.500" fontSize="sm">{errors.client_id}</Text>
                )}
              </Box>
              <Box>
                <FormLabel color={textColor} fontSize="sm" fontWeight="500">
                  Client Password
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    placeholder="Client Password"
                    name="client_password"
                    value={formData.client_password}
                    onChange={handleInputChange}
                  />
                  <InputRightElement>
                    <Icon
                      color="gray.400"
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.client_password && (
                  <Text color="red.500" fontSize="sm">{errors.client_password}</Text>
                )}
              </Box>
              <Box>
                <FormLabel
                  color={textColor}
                  fontSize="sm"
                  fontWeight="500"
                  mb="8px"
                >
                  Totp Key
                  <Text color={brandStars} display={'inline'}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  variant="auth"
                  type="text"
                  placeholder="totp key"
                  size="md"
                  name="totp_key"
                  value={formData.totp_key}
                  onChange={handleInputChange}
                />
                {errors.totp_key && (
                  <Text color="red.500" fontSize="sm">{errors.totp_key}</Text>
                )}
              </Box>
            </SimpleGrid>
          </FormControl>
        </Flex>
        <Flex justifyContent="center" mt="20px">
          <ButtonGroup spacing={4}>
            <Button
              onClick={handleAddAdmin} // Attach the handleAddBroker function
              fontSize="md"
              variant="brand"
              fontWeight="500"
            >
              Add <MdAdd />
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    );
  },
);

export default AddAdminForm;