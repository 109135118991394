// reducers/adminReducer.js
import { ADD_ACCOUNT_FAILURE, ADD_ACCOUNT_REQUEST, ADD_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE, GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS} from '../../redux/constants';
  
  const accountReducer = (state = {}, action) => {
    switch (action.type) {
      case ADD_ACCOUNT_REQUEST:
        return { ...state, loading: true, error: null };
      case ADD_ACCOUNT_SUCCESS:
        return { ...state, loading: false,accountAdd: action.payload, error: null };
      case ADD_ACCOUNT_FAILURE:
        return { ...state, loading: false,accountAdd: [], error: action.payload };
      case GET_ACCOUNT_REQUEST:
        return { ...state, loading: true, error: null };
      case GET_ACCOUNT_SUCCESS:
        return { ...state, loading: false,account: action.payload, error: null };
      case GET_ACCOUNT_FAILURE:
        return { ...state, loading: false,account: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default accountReducer;
  